<template>
  <section class="app-ecommerce-details">
    <desordre-list-add-new
      :is-add-new-desordre-sidebar-active.sync="isAddNewDesordreSidebarActive"
      @adddesordre='(indesordre) => addDesordre(indesordre)' 
        :composantsList="arrayComposants"
        :inDesordreData="inDesordreData"
    />
    <inspectionmodal :multi="false"  class="mt-2" :inspection="inspection" :noedit=true />
    <fiche-iqoa idtoshow="1" editable="1" />
    <!-- Content -->
    <b-card
    >
      <b-card-body v-if="creating===true">
          <b-row>
              <b-col>
                <form-wizard
                ref="myWizard"
                    color="#7367F0"
                    :title="null"
                    :subtitle="null"
                    :startIndex="myIndex"
                    finish-button-text="Submit"
                    back-button-text="Previous"
                    class="steps-transparent mb-3"
                    @on-complete="formSubmitted"
                    @on-change="onNextClick"
                    nextButtonText="Suivant"
                    backButtonText="Précédent"
                    finishButtonText="Terminer l'inspection"
                    >
                   
                    <!-- personal details -->
                    <tab-content
                        title="Lancement"
                        icon="feather icon-user"
                    >
                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >    
                            <p>Vous êtes sur le point de démarrer l'inspection. Vous pouvez consulter les détails de cette inspection en cliquant <b-link @click="goToInspection"> sur ce lien</b-link> ou encore télécharger <b-link> package documentaire</b-link>.</p>
              
                             <h6>Conditions de l'inspection</h6>
                             <b-row>
                               <b-col md="6">
                                  <b-form-group
                                  label="Moyens"
                                  label-for="i-budget"
                                  >
                                  <b-form-input
                                      id="i-moyen"
                                      placeholder=""
                                  />
                                  </b-form-group>  
                               </b-col>
                               <b-col md="6">
                                  <b-form-group
                                    label="Date de démarrage"
                                    label-for="startdate"
                                  >
                                    <b-form-datepicker
                                      id="startdate"
                                      trim
                                      locale="fr"
                                      v-bind="labels['fr'] || {}"
                                      placeholder=""
                                    />        
                                  </b-form-group>
                               </b-col>
                             </b-row>
                             <b-row>
                               <b-col md="6">
                                  <b-form-group
                                  label="Conditions climatiques"
                                  label-for="i-climat"
                                  >
                                  <b-form-input
                                      id="i-climat"
                                      placeholder=""
                                  />
                                  </b-form-group> 
                               </b-col>
                             </b-row>
                                              
 
                        </b-col>
                        </b-row>                    
                    
                    </tab-content>
                    <tab-content
                        title="Réalisation"
                        icon="feather icon-user"
                    >
                         <p v-if="inspection.type==='ID'">Inspection sur relevé graphique : Veuillez annoter les relevés graphiques associés à chacun des composants de l'ouvrage suivants.</p> 
                         <p v-if="inspection.type!=='ID'">Inspection sur fiche : Veuillez remplir les fiches associées à chacun des composants de l'ouvrage suivants.</p> 

                            <b-table
                                ref="refUserListTable"
                                class="position-relative"
                                :items="Composants.itemsComposant4"
                                responsive
                                :fields="fieldsComposant"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found"
                              >

                                <template #cell(releve)="data">
                                  <div class="text-nowrap">
                                    <b-link v-if="inspection.type==='ID'" @click="showReleve">Relevé graphique</b-link>
                                    <b-link v-if="inspection.type!=='ID'" @click="showFiche(data.item.fiche)">Fiche</b-link>
                                  </div>
                                </template>
                                <!-- Column: Role -->
                                <template #cell(type)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                                  </div>
                                </template>

                                <!-- Column: Role -->
                                <template #cell(iqoa)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.iqoa }}</span>
                                  </div>
                                </template>
                  

                              </b-table>

                    </tab-content>
                    <tab-content
                        title="Désordres"
                        icon="feather icon-user"
                        v-if="inspection.type!=='ID'"
                    >
                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                            Désordres
                            </h5>
                            <small class="text-muted">Veuillez définir les désordres/suites à donner associés.</small>
                        </b-col>
                        </b-row>
                        <b-row border="1" class="mb-2">
                          <b-col cols="12" >
                              <h6>Liste des désordres connus</h6>
                              <hr/>                              
                              <b-row class="ml-2" >
                                  <b-col cols="1">Date</b-col>
                                  <b-col cols="1">Type</b-col>
                                  <b-col cols="5">Libellé</b-col>
                                  <b-col cols="2">Suite à donner</b-col>
                                  <b-col cols="1">Statut</b-col>
                                  <b-col cols="2">Maintenance associée</b-col>
                              </b-row>       
                              <hr/>                              
                              <h6>GU156 - A142X - Corniche<span style="float:right"><b-link @click="onDesordreNew">Ajouter un désordre</b-link></span></h6>
                              <hr/>
                              <b-row class="ml-2" v-for="item in arrayDesordre1" :key="item.id">
                                  <b-col cols="1">{{ item.created }}</b-col>
                                  <b-col cols="1">{{ item.type }}</b-col>
                                  <b-col cols="5"><b-link @click="onDesordreEdit(item)">{{ item.libelle }}</b-link></b-col>
                                  <b-col cols="2">{{ item.suiteadonner }}</b-col>
                                  <b-col cols="1">{{ item.status }}</b-col>
                                  <b-col cols="2">{{ item.maintenance }}</b-col>
                              </b-row>       
                              <hr class="mt-2"/>
                              <h6>GU156 - A143V - Chaussée et revêtement du tablier<span style="float:right"><b-link @click="onDesordreNew">Ajouter un désordre</b-link></span></h6>
                              <hr/>
                              <b-row class="ml-2" v-for="item in arrayDesordre2" :key="item.id">
                                  <b-col cols="1">{{ item.created }}</b-col>
                                  <b-col cols="1">{{ item.type }}</b-col>
                                  <b-col cols="5"><b-link @click="onDesordreEdit(item)">{{ item.libelle }}</b-link></b-col>
                                  <b-col cols="2">{{ item.suiteadonner }}</b-col>
                                  <b-col cols="1">{{ item.status }}</b-col>
                                  <b-col cols="2">{{ item.maintenance }}</b-col>
                              </b-row>   
                              <hr class="mt-2"/>  
                              <h6>GU156 - B51 - Mur de soutènement<span style="float:right"><b-link @click="onDesordreNew">Ajouter un désordre</b-link></span></h6>
                              <hr/>
                              <b-row class="ml-2" v-for="item in arrayDesordre4" :key="item.id">
                                  <b-col cols="1">{{ item.created }}</b-col>
                                  <b-col cols="1">{{ item.type }}</b-col>
                                  <b-col cols="5"><b-link @click="onDesordreEdit(item)">{{ item.libelle }}</b-link></b-col>
                                  <b-col cols="2">{{ item.suiteadonner }}</b-col>
                                  <b-col cols="1">{{ item.status }}</b-col>
                                  <b-col cols="2">{{ item.maintenance }}</b-col>
                              </b-row>  
                              <hr class="mt-2"/>
                              <h6>GU156 - C1M59 - Bordure<span style="float:right"><b-link @click="onDesordreNew">Ajouter un désordre</b-link></span></h6>
                              <hr/>
                              <b-row class="ml-2" v-for="item in arrayDesordre3" :key="item.id">
                                  <b-col cols="1">{{ item.created }}</b-col>
                                  <b-col cols="1">{{ item.type }}</b-col>
                                  <b-col cols="5"><b-link @click="onDesordreEdit(item)">{{ item.libelle }}</b-link></b-col>
                                  <b-col cols="2">{{ item.suiteadonner }}</b-col>
                                  <b-col cols="1">{{ item.status }}</b-col>
                                  <b-col cols="2">{{ item.maintenance }}</b-col>
                              </b-row>                                                                                                                     
                          </b-col>
  
                        </b-row>             
                    </tab-content>

                    <tab-content
                        title="Conclusions"
                        icon="feather icon-user"
                    >


                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                            Conclusions
                            </h5>
                            <small class="text-muted">Veuillez indiquer vos conclusions pour cette inspection.</small>
                        </b-col>
                        </b-row>
                        <b-row border="1">
                          <b-col cols="12" style="min-height:400px;" >
                                <quill-editor
                                    v-model="content"
                                    :options="snowOption"
                                    style="height:300px;"
                                />                                                                                                                  
                          </b-col>
  
                        </b-row>             
                    </tab-content>

                    <!-- personal details -->
                    <tab-content
                        title="Rapport"
                        icon="feather icon-user"
                    >
                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                            Génération et lancement de la validation du rapport d'inspection.
                            </h5>
                        </b-col>
                        </b-row>
                        <h6>Le rapport d'inspection correspondant à votre inspection est disponible en cliquant sur le lien ci-dessous</h6>
                        <b-row>          
                                  <b-col class="m-1">
                                            <b-button
                                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                              variant="outline-primary"
                                          >
                                              Consulter le projet de rapport
                                          </b-button>
                                  </b-col>
                        </b-row>
                        <b-row  class="mb-4">
                            <b-col>
                              En cliquant sur le bouton "Terminer l'inspection" vous cloturer cette inspection, validez le rapport et le faites rentrer dans son circuit de validation.
                            </b-col>
                        </b-row>     
                                            
                    </tab-content>                                        

                    
                    </form-wizard>                  
              </b-col>
          </b-row>
        <b-row>

     
                                

        </b-row>
      </b-card-body>         
    </b-card>
  </section>
</template>


<script>
import { useRouter } from '@core/utils/utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import ouvragelistmodal from '@/components/OuvrageListModal.vue'
import FicheIqoa from '@/components/FicheComposant.vue'
import inspectionmodal from '@/views/apps/inspection/InspectionModal.vue'
import Composants from '@/@fake-db/data/apps/composant.js'

import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink,BButtonToolbar, BButtonGroup, 
  BButton, BDropdown, BDropdownItem, BAlert,BFormGroup, BFormInput, BFormCheckbox,BForm, BFormSelect,
  BListGroup, BListGroupItem, BFormDatepicker,BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import store from '@/store'

import desordreStoreModule from '../../desordre/desordreStoreModule'
import DesordreListAddNew from '../../desordre/DesordreListAddNew.vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
   props: {
    selected: {        
        type: Array,
            default: () => []
        },    
   },

   data() {

      this.$http.get('/apps/inspection/inspections/'+this.$router.currentRoute.params.id) 
          .then(response => {
              this.inspection = response.data
              if (this.inspection.status === 'en cours')
              this.myIndex = 1
              else if (this.inspection.status === 'rapport' && this.inspection.type === 'ID')
                this.myIndex = 2
                else if (this.inspection.status === 'rapport' && this.inspection.type !== 'ID')   
              this.myIndex = 3
              this.$root.$emit('update:startIndex', this.myIndex)

              this.$refs['myWizard'].activateAll()
              this.$refs['myWizard'].navigateToTab(this.myIndex)
          }) 


      this.onFetchData()

 
    
     return {
        inspection:{},
        myIndex: 0,
        snowOption: {
            theme: 'snow',
        },
        inDesordreData:{},
         content : "",
         creating : true,
        newOuvrage: {        
            type: Array,
                default: () => []
            }, 
        found: {
            type: Boolean,
                default: () => false
        } ,  
        labels: {
            fr: {
              labelNoDateSelected: 'Pas de date sélectionnée',
              labelHelp: ''
            },  
        }, 
        fieldsComposant : [
 
          { key: 'type',label:"Type", sortable: true, thStyle: { height: "30px" } },
          { key: 'iqoa',label:"IQOA", sortable: true , thStyle: { height: "30px" }},      
          { key: 'releve',label:"", thStyle: { height: "30px"} },     
        ] , 
        arrayDesordre1: [],   
        arrayDesordre2: [],
        arrayDesordre3: [],  
        arrayDesordre4: [], 
        arrayComposants: ['GU156 - A142X - Corniche','GU156 - A143V - Chaussée et revêtement du tablier','GU156 - B51 - Mur de soutènement','GU156 - C1M59 - Bordure'],                                                   
     }
   },
  components: {
      DesordreListAddNew,
    // BSV
    BCard,
    BTable,
    BCardBody,
    inspectionmodal,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    vSelect,
    BButtonToolbar,
    BButtonGroup,    
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup, 
    BFormInput, 
    BFormCheckbox,
    BForm,
    BFormSelect,
    FormWizard, 
    TabContent,
    BListGroup, 
    BListGroupItem, 
    FicheIqoa,
    BFormDatepicker,
    // SFC
    ToastificationContent,
    ouvragelistmodal,
    quillEditor,
  },

  methods: {
    formSubmitted() {
      this.inspection.status = "terminé"
      this.saveInspection()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Inspection terminée avec succès',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.creating = false
            setTimeout(() => {
      this.$router.push({ path: '/dashboard/inspecteur' })
      }, 500);
    },
    onNextClick: function(a,b) {
 
      if (a===0 && b===1) {
        this.inspection.status = "en cours"
        this.saveInspection()
      } else if ((this.inspection.type==='ID' && a===1 && b===2) || (this.inspection.type!=='ID' && a===2 && b===3)) {
        this.inspection.status = "rapport"
        this.saveInspection()
      }
    },

    saveInspection: function() {
        this.$http.post('/apps/inspection/inspections',this.inspection) 
          .then(response => {
              
          }) 
          .catch((e) => console.log(e))
    },

    showFiche: function (id) { 
      this.$root.$emit('bv::show::modal','modal-fiche')
    }, 

    showReleve: function (id) { 
       this.$router.push({ path: '/apps/releve/releve/'+this.inspection.id })
    }, 

    goToInspection() {
      this.$root.$emit('bv::show::modal','modal-inspection')
    },

    OnSelectOuvrage: function (arrayOuvrage) { 
      this.found = false   
        for (const [index, [, value]] of Object.entries(Object.entries(this.selected))) {
          if (value.name == arrayOuvrage.name) {
            this.found = true
          }
        }
        if (!this.found)  
          this.selected.push(arrayOuvrage)
    },
    addDesordre: function (desordre) { 

      if (desordre.composants == 'GU156 - A142X - Corniche' && !this.arrayDesordre1.find(x => x.id === desordre.id)) {
          this.arrayDesordre1.push(desordre)
      } else if (desordre.composants == 'GU156 - A143V - Chaussée et revêtement du tablier' && !this.arrayDesordre2.find(x => x.id === desordre.id)) {
          this.arrayDesordre2.push(desordre)
        } else if (desordre.composants == 'GU156 - C1M59 - Bordure'  && !this.arrayDesordre3.find(x => x.id === desordre.id)) {
                this.arrayDesordre3.push(desordre)
        } else if (desordre.composants == 'GU156 - B51 - Mur de soutènement'  && !this.arrayDesordre4.find(x => x.id === desordre.id)) {
                this.arrayDesordre4.push(desordre)
        }           

    },   

    onDesordreEdit: function (desordre) { 
       this.inDesordreData = desordre     
       this.isAddNewDesordreSidebarActive = true
    },       
 
     onDesordreNew: function () { 
       this.inDesordreData.id = ''  
       this.isAddNewDesordreSidebarActive = true
    },   

    onFetchData: function () { 
        store
            .dispatch('app-desordre/fetchDesordres', {
                q: '',
            })
            .then(response => {
                const { desordres, total } = response.data
                desordres.forEach(desordre => this.addDesordre(desordre));
            })
            .catch(() => {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching desordre list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
                })        
            }) 
    },  
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-desordre'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, desordreStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewDesordreSidebarActive = ref(false)
    const arrayComposants = []
    return {
        isAddNewDesordreSidebarActive,
        arrayComposants,
        Composants
    }
  },  
}
</script>

<style lang="scss" >
.nopad th, .nopad td {
    padding: 0px;
}
.ouvrage-list {
    height: 800px; /* or any value */
    overflow-y: auto;
}
div.minimalistBlack {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.divTable.minimalistBlack .divTableCell, .divTable.minimalistBlack .divTableHead {
  padding: 5px 4px;
}
.divTable.minimalistBlack .divTableBody .divTableCell {
  font-size: 13px;
}
.divTable.minimalistBlack .divTableRow:nth-child(even) {
  background: #D0E4F5;
}
.divTable.minimalistBlack .divTableHeading {
  background: #7367F0;
  background: -moz-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: -webkit-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: linear-gradient(to bottom, #968df4 0%, #8176f1 66%, #7367F0 100%);
}
.divTable.minimalistBlack .divTableHeading .divTableHead {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: left;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
}
/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group;}
.viewer {
  background-color:#D3E1FF;
}
.viewer p {
  color:#a81414;
  font-size: 14px;
  font-weight: bold;
  padding:100px;
}
.wizard-header{
  padding:0px !important;
}
</style>



